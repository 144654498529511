const JSZip = require("jszip");
const saveAs = require ("file-saver");

/**
 * Downloads a JSON object as a single csv or json file
 * @param {araay} content
 * @param {string} filename,
 * @param {string} fileType - 'csv' or 'json'
 * @returns {}
 */

function downloadFile(content, filename, fileType) {
  return new Promise((resolve) => {
    if (fileType === 'json') {
      fileType = 'application/json'
    }
    if (fileType === 'csv') {
      fileType = 'text/csv'
    }
    const blob = new Blob(content, { type: fileType });
    const a = document.createElement('a');
    a.download = filename;
    a.href = URL.createObjectURL(blob);
    a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(function revokeDownloadURL() {
      URL.revokeObjectURL(a.href);
    }, 10000);
    resolve()
  })
}

/**
 *  * Downloads an array of JSON object as zipped csv or json files.
 *  * @param {object} filenamesAndData - [{'name': clinicName, 'content': [fileContent]}, ...]
 *  * @param {string} filename,
 *  * @param {string} fileType - 'csv' or 'json'
 *  * @returns {}
 *  */
function downloadZip(filenamesAndData, foldername, fileType){
  return new Promise((resolve, reject) => {
  const zip = new JSZip();
  for (let file of filenamesAndData) {
    zip.file(
      file.name + "." + fileType,
      file.content
    );
  }
  zip.generateAsync({ type: "blob" }).then(content => {
    saveAs(content, foldername + ".zip")
    resolve()
  }).catch(err => reject(err))
})}

module.exports = {downloadZip, downloadFile};
